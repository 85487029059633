<template>
  <div>
    <el-row style="padding-right: 0px;">
      <el-col :span="12">
        <!-- 分公司库存情况报表 -->
        <el-card id="printContent" style="width: 820px;">
          <div slot="header" style="height:20px;line-height:20px;text-align:center;">
            <div style="float:left">
              <el-button type="primary" size="small" @click="printContent()">打印</el-button>
              <download-excel
                style="display:inline-block"
                :data="kucunTableData"
                :fields="json_fields"
                :header="title"
                :footer="footer"
                :name="title + '.xlsx'"
              >
                <el-button type="success" size="small">导出</el-button>
              </download-excel>
            </div>
            <span style="font-weight:bold">{{ thedate }} 各分公司库房库存报表</span>
            <div style="display:inline;margin-left:20px">
              <el-select v-model="company" placeholder="请选择分公司" v-if="currentUserCompany === '00'" @change="getCompany" size="small">
                <el-option v-for="item in companys_options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div style="float:right">
              <el-date-picker
                type="date"
                size="small"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="thedate"
                style="width: 150px;"
                @change="doChange"
              >
              </el-date-picker>
            </div>
          </div>

          <el-table
            v-loading="loading"
            :data="kucunTableData"
            border
            stripe
            highlight-current-row
            :header-row-style="TableHeaderRowStyle"
            :header-cell-style="TableHeaderCellStyle"
            :row-style="TableRowStyle"
            :cell-style="TableCellStyle"
            style="width: 100%"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="库房" label="库房" width="170"> </el-table-column>
            <el-table-column prop="材料" label="材料" width="180"> </el-table-column>
            <el-table-column prop="库存" label="库存" width="100"> </el-table-column>
            <el-table-column prop="待修板" label="待修板" width="100"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="10">
        <div style="height: 57px;"></div>
        <el-card style="width: 800px;">
          <el-table
            v-loading="loading"
            :data="ingongdiTableData"
            border
            stripe
            highlight-current-row
            :header-row-style="TableHeaderRowStyle"
            :header-cell-style="TableHeaderCellStyle"
            :row-style="TableRowStyle"
            :cell-style="TableCellStyle"
            style="width: 100%"
          >
            <el-table-column prop="材料" label="材料" width="180"> </el-table-column>
            <el-table-column prop="在工地" label="在工地" width="100"> </el-table-column>
            <el-table-column prop="在路上" label="在路上" width="100"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  data() {
    return {
      title: '',
      footer: '',
      json_fields: {
        库房: '库房',
        材料: '材料',
        库存: '库存',
        待修板: '待修板'
      },
      thedate: utils.getDate(),
      kucunTableData: [],
      ingongdiTableData: [],
      loading: false,
      company: utils.getUserCookie().company.code,
      company_name: '',
      currentUserCompany: utils.getUserCookie().company.code,
      companys_options: [],
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },

      TableHeaderRowStyle() {
        return 'height:30px'
      },
      TableHeaderCellStyle() {
        return 'padding-bottom:2px;padding-top:2px;font-size:14px;font-weight:700;color:#000;'
      },
      TableRowStyle() {
        return 'height:30px'
      },
      TableCellStyle() {
        return 'padding-bottom:2px;padding-top:2px;font-size:14px;color:#000;height:30px'
      }
    }
  },

  methods: {
    getTableData() {
      if (this.company != '00') {
        this.ingongdiTableData = []
        this.kucunTableData = []
        this.loading = true
        this.$axios
          .get('/fengongsiKucun/', {
            params: {
              date: this.thedate,
              company: this.company //当前用户所属分公司编号code
            }
          })
          .then(res => {
            this.kucunTableData = res.data.kucunTableData
            this.ingongdiTableData = res.data.ingongdiTableData
            this.title = this.thedate + ' ' + this.company_name + '库存报表'
          })
          .catch(function(error) {
            console.log(error)
            this.kucunTableData = []
            this.ingongdiTableData = []
          })
        this.loading = false
      }
    },
    //获取选择的分公司
    getCompany() {
      console.log('company', this.company)
      let cur_company_code = this.company
      let cur_company_name = ''
      console.log('cur_company_code', cur_company_code)
      console.log('companys_options:', this.companys_options)
      this.companys_options.forEach(function(item) {
        if (item.value === cur_company_code) {
          cur_company_name = item.label
        }
      })
      this.company_name = cur_company_name
      console.log('cur_company_name......:', cur_company_name)
      this.getTableData()
    },
    //获取分公司列表
    getCompanyList() {
      this.$axios.get('/getCompanyList/').then(res => {
        console.log('companys', res.data)
        res.data.forEach(element => {
          let company = {}
          company.label = element.name
          company.value = element.code
          this.companys_options.push(company)
        })
        console.log('companys_options', this.companys_options)
      })
    },
    doChange() {
      this.getTableData()
    },
    printContent() {
      utils.printContent('printContent')
    }
  },
  created() {
    this.getCompanyList()
    this.getTableData()
  }
}
</script>

<style scoped></style>
<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
